import React from "react";
import { Link } from "gatsby";

class HomePageHistory extends React.Component {
  constructor(props) {
    super(props);
    this.updateImage = this.updateImage.bind(this);
  }

  componentDidMount() {
    const imageSrc = this.props.image && this.props.image.url;
    const mobileImageSrc = this.props.mobileImage && this.props.mobileImage.url;
    const element = document.getElementsByClassName("history history--homepage")[0];

    if (typeof window !== 'undefined') {
      window.addEventListener("resize", this.updateImage);

      if (imageSrc) {
        if (window.innerWidth > 578) {
          element.style.backgroundImage = `url('${imageSrc}')`;
        } else {
          element.style.backgroundImage = `url('${mobileImageSrc}')`;
        }
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateImage);
  }

  updateImage() {
    const imageSrc = this.props.image && this.props.image.url;
    const mobileImageSrc = this.props.mobileImage && this.props.mobileImage.url;
    const element = document.getElementsByClassName("history history--homepage")[0];

    if (imageSrc) {
      if (window.innerWidth > 578) {
        element.style.backgroundImage = `url('${imageSrc}')`;
      } else {
        element.style.backgroundImage = `url('${mobileImageSrc}')`;
      }
    }
  }

  render() {
    const props = this.props;

    return (
      <section className="history history--homepage">
        <div className="history__center">
          <div className="history__location">
            <h2 className="history__header">{props.title}</h2>
            <div className="intro history__intro" dangerouslySetInnerHTML={{ __html: props.historyData.history_body }} />
            {/* <p className="intro history__intro">The Port of Long Beach was founded on June 24, 1911, a wild dream scratched out of 800 acres of mudflats at the mouth of the Los Angeles River. In the decades since then, those grand ambitions have been realized as the port has expanded to include more than 7,600 acres of state-of-the-art cargo terminals, roadways, bridges, rail yards and shipping channels. Today, the Port of Long Beach is one of the world's busiest seaports.</p> */}

            <Link to={props.historyData.history_link} className="view-more">View more</Link>
          </div>
        </div>
      </section>
    );
  }
}

export default HomePageHistory;