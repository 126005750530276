import React from "react";
import {Link} from "gatsby";

import BusinessCards from "src/components/business-cards";
import Events from "src/components/events";
import PortAtAGlance from "src/components/port-at-a-glance";
import HomePageHistory from "src/components/homepage-history";
import MapStatic from "src/components/map-static";
import NewsQuiltAll from "src/components/news-quilt-all";
import SocialMedia from "src/components/social-media";

/**
 *  Homepage components
 */
class MainHomepage extends React.Component {
    render() {
        const props = this.props;

        return (
            <React.Fragment>
                <div className="homepage-break-div"></div>
                <BusinessCards cardData={props.pageData} />
                <MapStatic headingContentType="0" introContentType="0"
                           copy={'Explore the Port of Long Beach! Find facility and tenant information, read about our projects, check out our environmental initiatives, find highlights from our history and more.'}
                           mapData={props.pageData}
                           mapBackground={props.pageData.static_map_background} />
                <PortAtAGlance glanceStats={props.pageData} />
                <NewsQuiltAll quiltPanelsCount="6" quiltStyle="pattern-02"/>
                <Link to="/port-info/news-and-press/" className="view-all full-bleed-maxed">View All</Link>
                <Events
                    title="Port Calendar"
                    description={props.pageData.calendar_description}
                    eventsStyle="1"
                    eventsType="mini-calendar"
                    link={props.pageData.calendar_link}
                />
                <HomePageHistory historyData={props.pageData} title={props.pageData.history_title} image={props.pageData.history_image} mobileImage={props.pageData.history_mobile_image} />
                <SocialMedia title="Around the Port" feed="portoflb" />
            </React.Fragment>
        );
    }
}

export default MainHomepage;
