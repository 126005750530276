import React from "react";
import photo from "src/images/business/business-port.png";

/**
 *  TEU section on Business main page
 */
class BusinessTEUSection extends React.Component {
  render() {
    const props = this.props;

    return (
      <section className="info gutter-rt business--info">
        <img src={props.teuData.numbers_image.url} alt="" className="media__asset info__img teu__img" />
        <div className="gutter">
          <h2 className="heading heading--med info__heading teu__heading">{props.teuData.numbers_title}</h2>

          <p className="intro">{props.teuData.numbers_description}</p>

          <ul className="business-stats">
            <li className="business-stat">
              <span className="business-stat__num line">{props.teuData.stat_one_number}</span>
              <span className="business-stat__txt">{props.teuData.stat_one_headline}</span>
              <div className="business-stat__description" dangerouslySetInnerHTML={{__html: props.teuData.stat_one_body}} />
              {/* <p className="business-stat__description">Our post-Panamax ship-to-shore gantry cranes, operated by skilled and record-setting longshore labor crews, efficiently service the world’s largest cargo vessels.</p> */}
            </li>
            <li className="business-stat">
              <span className="business-stat__num line">{props.teuData.stat_two_number}</span>
              <span className="business-stat__txt">{props.teuData.stat_two_headline}</span>
              <div className="business-stat__description" dangerouslySetInnerHTML={{__html: props.teuData.stat_two_body}} />
            </li>
            <li className="business-stat">
              <span className="business-stat__num line">{props.teuData.stat_three_number}</span>
              <span className="business-stat__txt">{props.teuData.stat_three_headline}</span>
              <div className="business-stat__description" dangerouslySetInnerHTML={{__html: props.teuData.stat_three_body}} />
            </li>
            <li className="business-stat">
              <span className="business-stat__num line">{props.teuData.stat_four_number}</span>
              <span className="business-stat__txt">{props.teuData.stat_four_headline}</span>
              <div className="business-stat__description" dangerouslySetInnerHTML={{__html: props.teuData.stat_four_body}} />
            </li>
            <li className="business-stat">
              <span className="business-stat__num line">{props.teuData.stat_five_number}</span>
              <span className="business-stat__txt">{props.teuData.stat_five_headline}</span>
              <div className="business-stat__description" dangerouslySetInnerHTML={{__html: props.teuData.stat_five_body}} />
            </li>
            <li className="business-stat">
              <span className="business-stat__num line">{props.teuData.stat_six_number}</span>
              <span className="business-stat__txt">{props.teuData.stat_six_headline}</span>
              <div className="business-stat__description" dangerouslySetInnerHTML={{__html: props.teuData.stat_six_body}} />
            </li>
          </ul>
        </div>
      </section>
    );
  }
}

export default BusinessTEUSection;