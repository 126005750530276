import React from "react";
import { Link } from "gatsby";

import air from "src/images/environment/card-air-2.jpg";
import emissions from "src/images/environment/card-our-zero-emissions-future-2.jpg";
import energy from "src/images/environment/card-energy-initiatives-2.jpg";
import trucks from "src/images/environment/card-clean-trucks-2.jpg";
import water from "src/images/environment/card-water-land-2.jpg";

/**
 *  Cards on Environment main page
 */
class EnvironmentCards extends React.Component {
  render() {
    const props = this.props;
    
    return (
      <section className="cards cards--environment gutter">
        <h2 className="heading heading--med line">{props.cardData.cards_headline}</h2>
        <p className="intro">{props.cardData.cards_description}</p>

        <ul className="card card--rect-5 card--environment">
          <li>
            <Link to={props.cardData.card_one_link}>
              <div className="card__photo-txt">
                <img src={props.cardData.card_one_image.url} alt="" />
                <p className="card__title">{props.cardData.card_one_title}</p>
              </div>
              <p className="card__blurb">{props.cardData.card_one_body}</p>
              <p className="view-more">View More</p>
            </Link>
          </li>
          <li>
            <Link to={props.cardData.card_two_link}>
              <div className="card__photo-txt">
                <img src={props.cardData.card_two_image.url} alt="" />
                <p className="card__title">{props.cardData.card_two_title}</p>
              </div>
              <p className="card__blurb">{props.cardData.card_two_body}</p>
              <p className="view-more">View More</p>
            </Link>
          </li>
          <li>
            <Link to={props.cardData.card_three_link}>
              <div className="card__photo-txt">
                <img src={props.cardData.card_three_image.url} alt="" />
                <p className="card__title">{props.cardData.card_three_title}</p>
              </div>
              <p className="card__blurb">{props.cardData.card_three_body}</p>
              <p className="view-more">View More</p>
            </Link>
          </li>
          <li>
            <Link to={props.cardData.card_four_link}>
              <div className="card__photo-txt">
                <img src={props.cardData.card_four_image.url} alt="" />
                <p className="card__title">{props.cardData.card_four_title}</p>
              </div>
              <p className="card__blurb">{props.cardData.card_four_body}</p>
              <p className="view-more">View More</p>
            </Link>
          </li>
          <li>
            <Link to={props.cardData.card_five_link}>
              <div className="card__photo-txt">
                <img src={props.cardData.card_five_image.url} alt="" />
                <p className="card__title">{props.cardData.card_five_title}</p>
              </div>
              <p className="card__blurb">{props.cardData.card_five_body}</p>
              <p className="view-more">View More</p>
            </Link>
          </li>
        </ul>
      </section>
    );
  }
}

export default EnvironmentCards;
