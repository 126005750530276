import React from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";

/**
 *  A single news quilt panel
 */
class NewsQuiltPanel extends React.Component {
  state = {
    defaultImg: ''
  }

  componentDidMount() {
    if (!this.props.imgPath && this.props.imgPathRandom) {
      import(`src/images/news/default-cover-img-${this.props.imgPathRandom}.jpg`).then(({ default: img }) => {
        this.setState({ defaultImg: img })
      });
    }
  }

  render() {
    const props = this.props;

    return (
      <li className="quilt__panel">
        <Link to={props.url} >
          {
            props.imgPath ?
              <img src={props.imgPath} alt="" />
              :
              <img src={this.state.defaultImg} alt="" />
          }
          <p className="quilt__title">{props.title}</p>
          {props.text && <p className="quilt__txt">{props.text}</p>}
          <p className="view-more">View More</p>
        </Link>
      </li>
      // <li className="quilt__panel">
      //   <Link to={props.url}>
      //     {
      //       props.imgPath ?
      //         <Img
      //           fluid={props.imgPath.localFile.childImageSharp.fluid}
      //         />
      //         :
      //         <img src={this.state.defaultImg} alt="" />
      //     }
      //     <p className="quilt__title">{props.title}</p>
      //     {props.text && <p className="quilt__txt">{props.text}</p>}
      //     <p className="view-more">View More</p>
      //   </Link>
      // </li>
    );
  }
}

export default NewsQuiltPanel;
