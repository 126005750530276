import React from "react";
import photo from "src/images/community/community-relations-2.jpg";

/**
 *  Gren Port module on Environment main page
 */
class CommunityInfo extends React.Component {
  render() {
    const props = this.props;

    return (
      <section className="info gutter-rt">
        <img src={props.image.url} alt="" className="media__asset info__img community-info-img" />
        <div className="gutter">    
          <h2 className="heading heading--med info__heading line">{this.props.title}</h2>

          <div className="intro content-stats" dangerouslySetInnerHTML={{__html: props.body}} />

          {/* <p className="intro">The Port makes an ongoing effort to support activities and school programs and to increase understanding of Port operations through open communication with the local community.</p>
          <p className="intro">Throughout the year the Port offers family-friendly events; provides opportunities to explore the Port by boat with Harbor Tours; sponsors events hosted by local organizations; and has speakers available to share the Port’s story.</p>
          <p className="intro">The Port also reaches out to teachers and young people, providing a number of diverse educational programs that allow students to master California content standards while discovering the seaport right in their own backyards.</p>
          <p className="intro">In an effort to encourage young people to continue their education, the Port also offers scholarships to local college students preparing for careers in international trade.</p>
          <p className="intro">The Port is proud to be a partner in the community, continually reaching out to ensure residents can learn more about Port operations, the complexities of maritime goods movement and how the Port is improving the environment and helping the community thrive.</p> */}

          <ul className="stats">
            <li className="stat">
              <span className="stat__num line">{props.stats.stat_one_percentage}</span>
              <span className="stat__txt">{props.stats.stat_one_text}</span>
            </li>
            <li className="stat">
              <span className="stat__num line">{props.stats.stat_two_percentage}</span>
              <span className="stat__txt">{props.stats.stat_two_text}</span>
            </li>
            <li className="stat">
              <span className="stat__num line">{props.stats.stat_three_percentage}</span>
              <span className="stat__txt">{props.stats.stat_three_text}</span>
            </li>
          </ul>
        </div>
      </section>
    );
  }
}

export default CommunityInfo;