import React from "react";
import { Link } from "gatsby";
import photo from "src/images/port-info/greenest-terminal.jpg"

/**
 *  Greenest Container on Port Info Main Page
 */
class PortInfoContainer extends React.Component {
  render() {
    const props = this.props;

    return (
      <section className="container container--grid">
        <img src={props.image.url} alt="" className="container__pic" />
        <div className="container__inner">
          <h2 className="container__heading">World's Greenest Container Terminal</h2>
          <div className="intro container__intro" dangerouslySetInnerHTML={{__html: props.text}} />
          {/* <p className="intro container__intro">The Middle Harbor Terminal Redevelopment Project is a 10-year construction program combining two aging shipping terminals into one of the world’s most advanced and greenest container terminals.</p> */}

          <Link to={props.link} className="view-more-port">View More</Link>
        </div>
      </section>
    );
  }
}

export default PortInfoContainer;