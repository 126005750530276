import React from "react";
import { Link } from "gatsby";
import moment from "moment";

const fetch = require('isomorphic-fetch')

/**
 *  Mini calendar on main pages (exs: Home, Commission, Community)
 */
class Events extends React.Component {
  constructor(props) {
    super(props);
    this.createEvents = this.createEvents.bind(this);
    this.getEvent = this.getEvent.bind(this);
  }

  state = {  
    isLoaded: false,
    events: []
  }

  /**
   * Load events data
   */
  componentDidMount() {
    const props = this.props;
    let fetchQuery;
    const today = moment().format("YYYY-MM-DD");

    // Categories Legend (slug and id):
    // * commission | 36
    // * board-meeting | 37
    // * community | 38
    const categories = props.category ? `categories=[board-meeting, ${props.category}]&` : '';

    switch(props.eventsType) {
      case 'mini-calendar':
        // retrieve six events beginning from today
        fetchQuery = `${process.env.GATSBY_API_URL}/wp-json/tribe/events/v1/events?${categories}start_date=${today}&per_page=6`;
        break;

      case 'sidebar':
        // retrieve one or more events to display below subnav, as specified by event_id tags on parent page
        fetchQuery = `${process.env.GATSBY_API_URL}/wp-json/tribe/events/v1/events?include=${props.eventsIds.join(',')}`;
        break;

      default:
        fetchQuery = null;
    }

    if (fetchQuery) {
      fetch(fetchQuery)
        .then(response => response.json())
        .then(json => {
          this.setState({
            isLoaded: true
          })

          this.createEvents(json);
        })
    }
  }

  /**
   * Add event objects array to state
   */
  createEvents(json) {
    const events = json.events.map( event => {
      const startDate = event.start_date;
      const monthName = moment(startDate).format("MMM");                  // ex: Jul
      const dayOfMonth = moment(startDate).format("D");                   // ex: 3
      const dayOfMonthWithLeadingZero = moment(startDate).format("DD");   // ex: 03
      const weekDay = moment(startDate).format("ddd");                    // ex: Wed
      const startTime = moment(startDate).format("h:mm A");               // ex: 4:15 PM

      return ({
        id: event.id,
        title: event.title,
        monthName: monthName,
        dayOfMonth: dayOfMonth,
        dayOfMonthWithLeadingZero: dayOfMonthWithLeadingZero,
        weekDay: weekDay,
        startTime: startTime
      })
    });

    this.setState({ events: events });
  }
 
  /**
   * Get JSX for an individual event listing
   */
  getEvent(event) {
    return (
      <li key={event.id}>
        <Link to={`/port-info/event-details/?e=${event.id}`} className="event">
          <p className="event__month-day"><span className="event__month">{event.monthName}</span> <span className="event__day">{event.dayOfMonthWithLeadingZero}</span></p>
          <p className="event__name">{event.title.replace(/&#8211;/, "-")}</p>
          <p><time className="event__datetime">{`${event.weekDay}, ${event.monthName} ${event.dayOfMonth}, ${event.startTime}`}</time></p>
        </Link>
      </li>
    )
  }

  render() {
    const props = this.props;

    return (
      <React.Fragment>
        {/* Multi-column events module that appears on Home, Commission, and Community. */}
        { props.eventsType === 'mini-calendar' &&
        <section className={`events-wrapper events-wrapper--v${props.eventsStyle} gutter`}>
          <div className="events">
            <h2 className="events__heading">Port <br className="events__heading--break" />Calendar</h2>
            <p className="events__descrip">{props.description}</p>
            <Link to={props.link} className="events__more events__more--1 view-more">View Calendar</Link>

            <ol className="events__list events__list--mini-calendar">
              { this.state.isLoaded && this.state.events.map(event => this.getEvent(event)) }
            </ol>

            <Link to={props.link} className="events__more events__more--2 view-more">View Calendar</Link>
          </div>
        </section>
        }

        {/* When one or more event is listed under the subnav */}
        { props.eventsType === 'sidebar' &&
        <React.Fragment>
          <ol className="events__list events__list--sidebar">
            { this.state.isLoaded && this.state.events.map(event => this.getEvent(event)) }
          </ol>
        </React.Fragment>
        }
      </React.Fragment>
    );
  }
}

export default Events;
