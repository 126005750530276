import React from "react";
import photo from "src/images/port-info/aerial-view-2.jpg";

/**
 *  Big Ship Era module on Port Info main page
 */
class PortInfoBigShip extends React.Component {
  render() {
    const props = this.props;

    return (
      <section className="port--info gutter-lt">
        <img src={props.image.url} alt="" className="info__img big--ship" />
        <div className="gutter">    
          <h2 className="heading heading--med port--info__heading line">About The Port</h2>

          <div className="intro port--info__text text-margin" dangerouslySetInnerHTML={{__html: props.text}} />

          {/* <p className="intro port--info__text">The Port of Long Beach is the premier U.S. gateway for trans-Pacific trade and a trailblazer in innovative goods movement, safety, environmental stewardship and sustainability. As the second-busiest container seaport in the United States, the Port handles trade valued at $170 billion annually and supports 2.6 million jobs across the nation, more than 575,000 in Southern California, and more than 50,000 jobs – or 1 in 5 – in Long Beach.
          <br /><br />The Port of Long Beach is one of the few U.S. ports that can welcome today’s largest vessels, serving 175 shipping lines with connections to 217 seaports around the world. Goods moving through the Port of Long Beach originate in or are destined for every congressional district in the United States. In 2019, the Port handled more than 7.6 million container units, achieving the second-best year in its history.
          <br /><br />Led by the five-member Board of Harbor Commissioners and Executive Director Mario Cordero, the Port is on track for a green future, creating the world’s most modern, efficient and sustainable seaport. As part of an industry-leading $4 billion capital improvement program this decade, the Port is building some of the most modern, efficient and sustainable marine facilities in the world to accommodate bigger and bigger ships, while generating thousands of new jobs.
          <br /><br />The Port of Long Beach prides itself on its culture of excellent customer service and the strong relationships it maintains with industry, community, environmental advocates and partner agencies.</p> */}
        </div>
      </section>
    );
  }
}

export default PortInfoBigShip;