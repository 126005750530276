import React from "react";

/**
 *  Social media feed
 */
class SocialMedia extends React.Component {

  componentDidMount() {
    // add juicer.io JS
    const script = document.createElement("script");
    script.src = "https://assets.juicer.io/embed.js";
    script.async = true;
    document.body.appendChild(script);

    // add juicer.io css
    const link = document.createElement("link");
    link.rel = "stylesheet";
    link.href = "https://assets.juicer.io/embed.css";
    document.head.appendChild(link);
  }

  render() {
    return (
      <section className="social-media gutter">
        <header className="social-media__header">
          <h2 className="heading heading--lg line">{this.props.title}</h2>

          <ul className="connect__social">
            <li>
              <a href="http://www.facebook.com/PortofLongBeach" rel="noopener noreferrer" target="_blank" aria-label="Port of Long Beach Facebook page"><svg className="icon icon--footer"><use xlinkHref="#icon-facebook" /></svg></a>
            </li>
            <li>
              <a href="https://twitter.com/portoflongbeach" rel="noopener noreferrer" target="_blank" aria-label="Port of Long Beach Twitter page"><svg className="icon icon--footer"><use xlinkHref="#icon-twitter" /></svg></a>
            </li>
            <li>
              <a href="https://www.instagram.com/PortOfLongBeach/" rel="noopener noreferrer" target="_blank" aria-label="Port of Long Beach Instagram page"><svg className="icon icon--footer"><use xlinkHref="#icon-instagram" /></svg></a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/port-of-long-beach/?viewAsMember=true" rel="noopener noreferrer" target="_blank" aria-label="Port of Long Beach LinkedIn page"><svg className="icon icon--footer"><use xlinkHref="#icon-linkedin" /></svg></a>
            </li>
          </ul>
        </header>

        {/* Juicer.io feed that is populated by their script. Note that their documentation says to use a <ul> but they populate that <ul> with a mixture of <li>s, <div>s, and other tags. API docs: https://www.juicer.io/social-media-api-juicer. */}
        <ul className="juicer-feed" data-feed-id={this.props.feed} data-per="8" data-gutter="38" data-columns="4"></ul>
      </section>
    );
  }
}

export default SocialMedia;
