import React from "react";

import EnvironmentCards from "src/components/environment-cards";
import EnvironmentGreenPort from "src/components/environment-green-port";
import MapStatic from "src/components/map-static";
import NewsQuiltEnvironment from "src/components/news-quilt-environment";

/**
 *  Environment main page components
 */
class MainEnvironment extends React.Component {
  render() {
    const props = this.props;

    return (
      <React.Fragment>
        <EnvironmentCards cardData={props.pageData} />
        <MapStatic headingContentType="1" introContentType="1" mapData={props.pageData} mapBackground={props.pageData.static_map_background} />
        <EnvironmentGreenPort title={props.pageData.stats_title} body={props.pageData.stats_body} stats={props.pageData} image={props.pageData.stats_image} />
        <NewsQuiltEnvironment quiltStyle="pattern-01" />
      </React.Fragment>
    );
  }
}

export default MainEnvironment;
