import React from "react";
import { Link } from "gatsby";

import jobs from "src/images/business/card-jobs-2.jpg";
import truckers from "src/images/business/card-truckers-2.jpg";
import business from "src/images/business/card-business-opportunities-2.jpg";
import cargo from "src/images/business/card-cargo-owners-2.jpg";
import ocean from "src/images/business/card-ocean-carriers-2.jpg";
// import tenants from "src/images/business/card-tenants-2.jpg";
import home from "src/images/business/r_commissioner_home.jpg";

/**
 *  Cards on Business main page
 */
class BusinessCards extends React.Component {
  render() {
    const props = this.props;
    
    return (
      <section className="cards cards--business gutter">
        <h2 className="heading heading--med line">{props.cardData.cards_title}</h2>
        <p className="intro">{props.cardData.cards_description}</p>

        <ul className="card card--rect-6">
          <li>
            <Link to={props.cardData.card_one_link}>
              <div className="card__photo-txt">
                <img src={props.cardData.card_one_image.url} alt="" />
                <p className="card__title">{props.cardData.card_one_title}</p>
              </div>
              <p className="card__blurb">{props.cardData.card_one_body}</p>
              <p className="view-more">View More</p>
            </Link>
          </li>
          <li>
            <Link to={props.cardData.card_two_link}>
              <div className="card__photo-txt">
                <img src={props.cardData.card_two_image.url} alt="" />
                <p className="card__title">{props.cardData.card_two_title}</p>
              </div>
              <p className="card__blurb">{props.cardData.card_two_body}</p>
              <p className="view-more">View More</p>
            </Link>
          </li>
          <li>
            <Link to={props.cardData.card_three_link}>
              <div className="card__photo-txt">
                <img src={props.cardData.card_three_image.url} alt="" />
                <p className="card__title">{props.cardData.card_three_title}</p>
              </div>
              <p className="card__blurb">{props.cardData.card_three_body}</p>
              <p className="view-more">View More</p>
            </Link>
          </li>
          <li>
            <Link to={props.cardData.card_four_link}>
              <div className="card__photo-txt">
                <img src={props.cardData.card_four_image.url} alt="" />
                <p className="card__title">{props.cardData.card_four_title}</p>
              </div>
              <p className="card__blurb">{props.cardData.card_four_body}</p>
              <p className="view-more">View More</p>
            </Link>
          </li>
          <li>
            <Link to={props.cardData.card_five_link}>
              <div className="card__photo-txt">
                <img src={props.cardData.card_five_image.url} alt="" />
                <p className="card__title">{props.cardData.card_five_title}</p>
              </div>
              <p className="card__blurb">{props.cardData.card_five_body}</p>
              <p className="view-more">View More</p>
            </Link>
          </li>
          <li>
            <Link to={props.cardData.card_six_link}>
              <div className="card__photo-txt">
                <img src={props.cardData.card_six_image.url} alt="" />
                <p className="card__title">{props.cardData.card_six_title}</p>
              </div>
              <p className="card__blurb">{props.cardData.card_six_body}</p>
              <p className="view-more">View More</p>
            </Link>
          </li>
          {/* <li>
            <Link to="/resources/tenants/">
              <div className="card__photo-txt">
                <img src={tenants} alt="" />
                <p className="card__title">Tenants</p>
              </div>
              <p className="card__blurb">Port operations, statistics, and wharfinger contact information.</p>
              <p className="view-more">View More</p>
            </Link>
          </li> */}
        </ul>
      </section>
    );
  }
}

// polb.com/webcasts 

export default BusinessCards;
