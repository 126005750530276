import React from "react";

import BusinessCards from "src/components/business-cards";
import BusinessTEUSection from "src/components/business-teu-section";
import MapStatic from "src/components/map-static";
import NewsQuiltBusiness from "src/components/news-quilt-business";

/**
 *  Business main page components
 */
class MainBusiness extends React.Component {
  render() {
    const props = this.props;

    return (
      <React.Fragment>
        <BusinessCards cardData={props.pageData} />
        <MapStatic headingContentType="0" introContentType="0" mapData={props.pageData} mapBackground={props.pageData.static_map_background} />
        <BusinessTEUSection teuData={props.pageData} />
        <NewsQuiltBusiness quiltStyle="pattern-01" />
      </React.Fragment>
    );
  }
}

export default MainBusiness;
