// import React from "react";
// import { Link } from "gatsby";

// import NewsQuiltPanel from "src/components/news-quilt-panel";

// /**
//  *  News panels arranged in quilt pattern
//  */
// class NewsQuilt extends React.Component {
//   // Set number of panels to 3 if quiltPanelsCount not specified
//   state = {
//     newsItems: this.props.newsQuiltData.edges.slice(0, parseInt(this.props.quiltPanelsCount || 3, 10))
//   }

//   render() {
//     const props = this.props;
//     const pathPrefix = '/port-info/news-and-press';

//     // Determine if panel is odd or even
//     const isEven = (n) => {
//       return n % 2 === 0;
//     }

//     // Generate random default image # from 1–4
//     const getRandomDefaultImg = () => {
//       const num = Math.floor(Math.random() * 4) + 1;
//       return num;
//     }

//     return (
//       <section>
//         <h2 className="visuallyhidden">News Articles</h2>
//         <ul className={`quilt quilt--${props.quiltStyle} full-bleed-maxed`}>
//           {
//             /*
//               There are two quilt display patterns: pattern-01 and pattern-02. Each dictates the order of img and text panels. See _quilt.scss for more details.
//             */
//             this.state.newsItems.map( (item, index) => {
//               const data = item.node;
//               let img = '';
//               let imgPathRandom = '';
//               let text = '';

//               // Set image or text depending on quilt style and whether panel is even or odd
//               if ((props.quiltStyle === 'pattern-01' && isEven(index)) || (props.quiltStyle === 'pattern-02' && !isEven(index))) {

//                 if (data.acf.quilt_image_square) {
//                   img = data.acf.quilt_image_square;
//                 } else {
//                   imgPathRandom = getRandomDefaultImg();
//                 }
//               } else {
//                 text = data.acf.news_excerpt;
//               }

//               // Construct props to set on quilt panel
//               const panel = {
//                 imgPath: img,
//                 imgPathRandom: imgPathRandom,
//                 text: text,
//                 title: data.title.replace(/&#8217;/, "'"),
//                 url: pathPrefix + data.path,
//               }

//               return (
//                 <NewsQuiltPanel key={data.id} {...panel} />
//               )
//             })
//           }
//         </ul>

//         { (this.props.quiltPanelsCount !== "15" && this.props.quiltPanelsCount !== "6") && 
//           <Link to="/port-info/news-and-press/" className="view-all full-bleed-maxed">View All</Link>
//         }
//       </section>
//     );
//   }
// }

// export default NewsQuilt;











import React from "react";
import { Link } from "gatsby";

import NewsQuiltPanel from "src/components/news-quilt-panel";

/**
 *  News panels arranged in quilt pattern
 */
class NewsQuilt extends React.Component {
  // Set number of panels to 3 if quiltPanelsCount not specified
  state = {
    newsItems: this.props.newsQuiltData.slice(0, parseInt(this.props.quiltPanelsCount || 3, 10))
    // newsItems: this.props.newsQuiltData
  }

  render() {
    const props = this.props;
    const pathPrefix = '/port-info/news-and-press';

    // Determine if panel is odd or even
    const isEven = (n) => {
      return n % 2 === 0;
    }

    // Generate random default image # from 1–4
    const getRandomDefaultImg = () => {
      const num = Math.floor(Math.random() * 4) + 1;
      return num;
    }

    return (
      <section>
        <h2 className="visuallyhidden">News Articles</h2>
        <ul className={`quilt quilt--${props.quiltStyle} full-bleed-maxed`}>
          {
            /*
              There are two quilt display patterns: pattern-01 and pattern-02. Each dictates the order of img and text panels. See _quilt.scss for more details.
            */
            this.state.newsItems.map( (item, index) => {
              const data = item;
              let img = '';
              let imgPathRandom = '';
              let text = '';

              // Set image or text depending on quilt style and whether panel is even or odd
              if ((props.quiltStyle === 'pattern-01' && isEven(index)) || (props.quiltStyle === 'pattern-02' && !isEven(index))) {

                if (data.acf.quilt_image_square) {
                  img = data.acf.quilt_image_square;
                } else {
                  imgPathRandom = getRandomDefaultImg();
                }
              } else {
                text = data.acf.news_excerpt;
              }

              // Construct props to set on quilt panel
              const panel = {
                id: data.id,
                imgPath: img,
                imgPathRandom: imgPathRandom,
                text: text,
                title: data.title.rendered.replace(/&#8217;/g, "'").replace(/&#8216;/g, "'").replace(/&#038;/g, "&"),
                url: pathPrefix + data.link.slice(24),
              }

              return (
                <NewsQuiltPanel key={data.id} {...panel} />
              )
            })
          }
        </ul>

        { (this.props.quiltPanelsCount !== "15" && this.props.quiltPanelsCount !== "6") && 
          <Link to="/port-info/news-and-press/" className="view-all full-bleed-maxed">View All</Link>
        }
      </section>
    );
  }
}

export default NewsQuilt;