import React from "react";
import { Link } from "gatsby";


class PortAtAGlance extends React.Component {
    render () {
        const props = this.props;

        return (
            <section className="glance glance--main gutter">
                <div className="glance__grid">
                    <div className="glance__info">
                        <h2 className="glance__heading">{props.glanceStats.glance_title}</h2>
                        <p className="intro glance__intro">{props.glanceStats.glance_description}</p>

                        <Link to={props.glanceStats.glance_link} className="glance__view view-more">View More</Link>
                    </div>
                    <div className="glance__stat">
                        <span className="glance__num line">{props.glanceStats.stat_one_number}</span>
                        <span className="glance__txt">{props.glanceStats.stat_one_headline}</span>
                        {/* <p className="glance__intro">One in five jobs in Long Beach is linked to the trade, tourism or construction at the Port, making the city’s seaport the single most important economic engine locally.</p> */}
                    </div>
                    <div className="glance__stat">
                        <span className="glance__num line">{props.glanceStats.stat_two_number}</span>
                        <span className="glance__txt">{props.glanceStats.stat_two_headline}</span>
                        {/* <p className="glance__intro">The port complex is crisscrossed by more than 100 miles of track, helping to bring in exports and haul out imports. The nation’s best short line railroad, Pacific Harbor Line, operates our rail facilities.</p> */}
                    </div>
                    <div className="glance__stat">
                        <span className="glance__num line">{props.glanceStats.stat_three_number}</span>
                        <span className="glance__txt">{props.glanceStats.stat_three_headline}</span>
                        {/* <p className="glance__intro">Environmental initiatives have dramatically reduced pollution from Port-related sources.</p> */}
                    </div>
                </div>
            </section>
        );
    }
}

export default PortAtAGlance;