import React from "react";

import CommissionCards from "src/components/commission-cards";
import CommissionHeadline from "src/components/commission-headline";
import CommissionHistory from "src/components/commission-history";
import Events from "src/components/events";

/**
 *  Commission main page components
 */
class MainCommission extends React.Component {
  render() {
    const props = this.props;
    
    return (
      <React.Fragment>
        <CommissionCards cardData={props.pageData} />
        <CommissionHeadline title={props.pageData.about_the_commission_title} text={props.pageData.about_the_commission_body} image={props.pageData.about_the_commission_image} contactInfo={props.pageData} />
        <Events 
          title="Harbor Commissioners Calendar" 
          description={props.pageData.calendar_description}
          category="commission" 
          eventsStyle="1"
          eventsType="mini-calendar"
          link={props.pageData.calendar_link}
        />
        <CommissionHistory title={props.pageData.commission_history_title} text={props.pageData.commission_history_description} link={props.pageData.commission_history_link} image={props.pageData.history_image} mobileImage={props.pageData.history_mobile_image} />
      </React.Fragment>
    );
  }
}

export default MainCommission;
