import React from "react";
import { Link } from "gatsby";


class PortInfoSpruceGoose extends React.Component {
    constructor(props) {
        super(props);
        this.updateImage = this.updateImage.bind(this);
    }

    componentDidMount() {
        if (typeof window !== 'undefined') {
            window.addEventListener("resize", this.updateImage);
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateImage);
    }

    updateImage() {
        const imageSrc = this.props.image && this.props.image.url;
        const mobileImageSrc = this.props.mobileImage && this.props.mobileImage.url;
        const element = document.getElementsByClassName("history history--port-info")[0];

        if (imageSrc) {
            if (window.innerWidth > 578) {
                element.style.backgroundImage = `url('${imageSrc}')`;
            } else {
                element.style.backgroundImage = `url('${mobileImageSrc}')`;
            }
        }
    }

    render() {
        const props = this.props;

        return (
            <section className="history history--port-info">
                <div className="history__grid">
                    <div className="history__info">
                        <h2 className="history__heading">{props.title}</h2>
                        <p className="intro history__intro">{props.text}</p>

                        <Link to={props.link} className="view-more">Read More</Link>
                    </div>
                </div>
            </section>
        );
    }
}

export default PortInfoSpruceGoose;