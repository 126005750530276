import React from "react";
import { Link } from "gatsby";

/**
 *  Static map promo for interactive map on Home, Business, and Environment main pages
 */
class MapStatic extends React.Component {
  componentDidMount() {
    const imageSrc = this.props.mapBackground && this.props.mapBackground.url;
    const element = document.getElementsByClassName("map-static grid gutter")[0];

    if (imageSrc) {
      element.style.backgroundImage = `url('${imageSrc}')`;
    }
  }

  render() {
    const props = this.props;

    // Each rendering of the map has one heading and one intro as specified by props
    const headingContent = [
      'Port of Long Beach Interactive Map',
      'Learn About Our Environment'
    ];

    const introContent = [
      'Use our interactive map to find terminal and other facility information, data and articles on capital improvement projects and other highlights.',
      'The Port of Long Beach has made tremendous improvements to the environment in recent years. Guided by our award-winning Green Port Policy, we are reducing harmful air emissions from port-related operations, improving water quality in the harbor, protecting marine wildlife and implementing environmentally sustainable practices throughout the Port.'
    ];

    return (
      <React.Fragment>
        {
          props.mapData && props.mapData.static_map_headline ?
            <section className={`map-static grid gutter ${props.classModifier ? `map-static--${props.classModifier}` : ''}`}>
              <div className="map-static__inner">
                <h2 className="map-static__heading">{props.mapData.static_map_headline}</h2>
                <div className="intro map-static__intro" dangerouslySetInnerHTML={{ __html: props.mapData.static_map_body }} />
                {/* <p className="intro map-static__intro">{props.mapData.environment_map_body}</p> */}

                <Link to={props.mapData.static_map_link} className="view-map">View Map</Link>
              </div>
            </section>
            :
            <section className={`map-static grid gutter ${props.classModifier ? `map-static--${props.classModifier}` : ''}`}>
              <div className="map-static__inner">
                <h2 className="map-static__heading">{headingContent[props.headingContentType]}</h2>
                <p className="intro map-static__intro">{this.props.copy ? this.props.copy : introContent[props.introContentType]}</p>

                <Link to="/port-info/map/" className="view-map">View Map</Link>
              </div>
            </section>
        }
      </React.Fragment>
    );
  }
}

export default MapStatic;


