import React from "react";
import {Link} from "gatsby";
import photo from "src/images/commission/commission-headline.jpg";

// import DropDown from "src/components/drop-down"; // TODO: may need this later

class CommissionHeadline extends React.Component {
  render() {
    const props = this.props;

    return (
      <section className="headline headline--grid">
        <img src={props.image.url} alt="" className="headline__img"/>
        <div className="headline__commission">
          <h2 className="headline__heading heading--med line">{props.title}</h2>

          <div className="headline__intro intro text-margin" dangerouslySetInnerHTML={{__html: props.text}} />

          {/* <p className="headline__intro intro short-margin">
            Five Harbor Commissioners, who set policy and appoint the Port's Executive Director, are appointed by the
            Mayor and confirmed by the City Council. They may serve up to two six-year terms. Each July, commissioners
            elect board officers for one-year terms.
          </p> */}

          {/* <p className="headline__intro intro">
            Port lands are owned by the City of Long Beach in trust for the people of the State of California and cannot
            be sold to any private enterprises. The <a href="http://www.slc.ca.gov/PublicTrust/PublicTrust.html"
                                                       rel="noopener noreferrer"
                                                       target="_blank">Tidelands Trust</a> gives the city the right to
            manage and develop the
            Harbor District for the sole purposes of commerce, navigation, fisheries and recreation. The <a
            href="http://library.municode.com/index.aspx?clientID=16854&stateID=5&statename=California"
            rel="noopener noreferrer" target="_blank">City Charter</a> created the Long Beach Harbor Department to
            promote and develop the Port.
          </p> */}
        </div>
        <div className="headline__contact">
          <h2 className="headline__heading contact--title heading--med line">{props.contactInfo.contact_title}</h2>
          <div className="contact--grid">
            <div className="contact--grid__section section--one">
              <h2 className="headline__heading contact--subtitle heading--med">{props.contactInfo.contact_section_one_header}</h2>
              <div className="headline__intro intro" dangerouslySetInnerHTML={{__html: props.contactInfo.contact_section_one_body}} />
              {/* <p className="headline__intro intro">{props.contactInfo.contact_section_one_body}</p> */}
            </div>
            <div className="contact--grid__section section--two">
              <h2 className="headline__heading contact--subtitle heading--med">{props.contactInfo.contact_section_two_header}</h2>
              <p className="headline__intro intro">{props.contactInfo.contact_section_two_body}</p>
            </div>
            <div className="contact--grid__section section--three">
              <h2 className="headline__heading contact--subtitle heading--med headline__heading__lowercase">{props.contactInfo.contact_section_three_header}</h2>
              <p className="headline__intro intro">{props.contactInfo.contact_section_three_body}</p>
            </div>
            <div className="contact--grid__section section--four">
              <h2 className="headline__heading contact--subtitle heading--med headline__heading__lowercase">{props.contactInfo.contact_section_four_header}</h2>
              <p className="headline__intro intro">{props.contactInfo.contact_section_four_body}</p>
            </div>
            <Link to="/port-info/news-and-press/contact-the-harbor-commission-12-18-2019/" className="main--link"><span className="main--link__text">Email the Harbor Commission</span></Link>
          </div>
        </div>
        {/*<DropDown />*/}
      </section>
    );
  }
}

export default CommissionHeadline;
