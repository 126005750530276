import React from "react";

import PortInfoBigShip from "src/components/port-info-big-ship";
import PortInfoContainer from "src/components/port-info-container";
import PortInfoSpruceGoose from "src/components/spruce-goose";
import NewsQuiltAll from "src/components/news-quilt-all.js";

/**
 *  Port Info main page components
 */
class MainPortInfo extends React.Component {
  render() {
    const props = this.props;

    return (
      <React.Fragment>
        <PortInfoBigShip text={props.pageData.about_the_port_text} image={props.pageData.about_the_port_image} />
        <PortInfoContainer text={props.pageData.greenest_container_text} image={props.pageData.greenest_container_image} link={props.pageData.greenest_container_link} />
        <PortInfoSpruceGoose title={props.pageData.decades_title} text={props.pageData.decades_description} link={props.pageData.decades_link} image={props.pageData.decades_image} mobileImage={props.pageData.decades_mobile_image} />
        <NewsQuiltAll quiltStyle="pattern-01" />
      </React.Fragment>
    );
  }
}

export default MainPortInfo;
