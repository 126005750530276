import React from "react";
import { Link } from "gatsby";

import education from "src/images/community/card-education-programs-2.jpg";
import grants from "src/images/community/card-grants.jpg";
import relations from "src/images/community/card-relations-requests-2.jpg";
import sponsorships from "src/images/community/card-sponsorships-2.jpg";
import tours from "src/images/community/card-harbor-tours-2.jpg";

/**
 *  Cards on Community main page
 */
class CommunityCards extends React.Component {
  render() {
    const props = this.props;

    return (
      <section className="cards cards--community gutter">
        <h2 className="heading heading--med line">{props.cardData.cards_headline}</h2>
        <p className="intro">{props.cardData.cards_description}</p>

        <ul className="card card--rect-5 card--community">
          <li>
            <Link to={props.cardData.card_one_link}>
              <div className="card__photo-txt">
                <img src={props.cardData.card_one_image.url} alt="" />
                <p className="card__title">{props.cardData.card_one_title}</p>
              </div>
              <p className="card__blurb">{props.cardData.card_one_body}</p>
              <p className="view-more">View More</p>
            </Link>
          </li>
          <li>
            <Link to={props.cardData.card_two_link}>
              <div className="card__photo-txt">
                <img src={props.cardData.card_two_image.url} alt="" />
                <p className="card__title">{props.cardData.card_two_title}</p>
              </div>
              <p className="card__blurb">{props.cardData.card_two_body}</p>
              <p className="view-more">View More</p>
            </Link>
          </li>
          <li>
            <Link to={props.cardData.card_three_link}>
              <div className="card__photo-txt">
                <img src={props.cardData.card_three_image.url} alt="" />
                <p className="card__title">{props.cardData.card_three_title}</p>
              </div>
              <p className="card__blurb">{props.cardData.card_three_body}</p>
              <p className="view-more">View More</p>
            </Link>
          </li>
          <li>
            <Link to={props.cardData.card_four_link}>
              <div className="card__photo-txt">
                <img src={props.cardData.card_four_image.url} alt="" />
                <p className="card__title">{props.cardData.card_four_title}</p>
              </div>
              <p className="card__blurb">{props.cardData.card_four_body}</p>
              <p className="view-more">View More</p>
            </Link>
          </li>
          <li>
            <Link to={props.cardData.card_five_link}>
              <div className="card__photo-txt">
                <img src={props.cardData.card_five_image.url} alt="" />
                <p className="card__title">{props.cardData.card_five_title}</p>
              </div>
              <p className="card__blurb">{props.cardData.card_five_body}</p>
              <p className="view-more">View More</p>
            </Link>
          </li>
        </ul>
      </section>
    );
  }
}

export default CommunityCards;
