import React from "react";
import { Link } from "gatsby";

import commissionerNeal from "src/images/commission/card-neal.jpg"
import president from "src/images/commission/card-lowenthal.jpg";
import vp from "src/images/commission/card-secretary-frank-colonna.jpg";
import commissionerOlvera from "src/images/commission/card-commisioner-bobby-olvera-2.jpg";
import commissionerWeissman from "src/images/commission/card-commissioner-weissman.jpg";

/**
 *  Cards on Commission main page
 */
class CommissionCards extends React.Component {
  render() {
    const cardData = this.props.cardData;

    return (
      <section className="cards cards--commission gutter">
        <h2 className="heading heading--med line">{cardData.commission_card_headline}</h2>
        <p className="intro">{cardData.commission_card_description}</p>

        <ul className="card card--rect-5 card--commission">
          <li>
            <Link to={cardData.card_one_link} className="card__link card__link--commission" title="View Bio">
              <div className="card__photo-txt">
                <img src={cardData.card_one_image.url} alt="" />
                <p className="card__title">{cardData.card_one_title}</p>
                <p className="card__name">{cardData.card_one_name}</p>
              </div>
            </Link>
          </li>
          <li>
            <Link to={cardData.card_two_link} className="card__link card__link--commission" title="View Bio">
              <div className="card__photo-txt">
                <img src={cardData.card_two_image.url} alt="" />
                <p className="card__title">{cardData.card_two_title}</p>
                <p className="card__name">{cardData.card_two_name}</p>
              </div>
            </Link>
          </li>
          <li>
            <Link to={cardData.card_three_link} className="card__link card__link--commission" title="View Bio">
              <div className="card__photo-txt">
                <img src={cardData.card_three_image.url} alt="" />
                <p className="card__title">{cardData.card_three_title}</p>
                <p className="card__name">{cardData.card_three_name}</p>
              </div>
            </Link>
          </li>
          <li>
            <Link to={cardData.card_four_link} className="card__link card__link--commission" title="View Bio">
              <div className="card__photo-txt">
                <img src={cardData.card_four_image.url} alt="" />
                <p className="card__title">{cardData.card_four_title}</p>
                <p className="card__name">{cardData.card_four_name}</p>
              </div>
            </Link>
          </li>
          <li>
            <Link to={cardData.card_five_link} className="card__link card__link--commission" title="View Bio">
              <div className="card__photo-txt">
                <img src={cardData.card_five_image.url} alt="" />
                <p className="card__title">{cardData.card_five_title}</p>
                <p className="card__name">{cardData.card_five_name}</p>
              </div>
            </Link>
          </li>
        </ul>
      </section>
    );
  }
}

export default CommissionCards;
