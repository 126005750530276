import React from "react";
import photo from "src/images/environment/green-port-seals.jpg";

/**
 *  Green Port module on Environment main page
 */
class EnvironmentGreenPort extends React.Component {
  render() {
    const props = this.props;

    return (
      <section className="info green-port gutter-rt">
        <img src={props.image.url} alt="" className="media__asset info__img green-port-img" />
        <div className="gutter">    
          <h2 className="heading heading--med info__heading line">{props.title}</h2>

          <div className="intro content-stats" dangerouslySetInnerHTML={{__html: props.body}} />

          {/* <p className="intro">The Port of Long Beach is committed to improving the environment, as demonstrated by its 20-year record of environmental protection programs. The Green Port Policy is an aggressive, comprehensive and coordinated approach to reduce the negative impacts of Port operations. The Green Port Policy, which the Board adopted in January 2005, serves as a guide for decision making and established a framework for environmentally friendly Port operations. The policy’s five guiding principles are:</p> */}

          {/* <ul className="ul info__list">
            <li className="ul__li info__item">Protect the community from harmful environmental impacts of Port operations.</li>
            <li className="ul__li info__item">Distinguish the Port as a leader in environmental stewardship and compliance.</li>
            <li className="ul__li info__item">Promote sustainability.</li>
            <li className="ul__li info__item">Employ best available technology to avoid or reduce environmental impacts.</li>
            <li className="ul__li info__item">Engage and educate the community.</li>
          </ul> */}

          <ul className="stats">
            <li className="stat">
              {/* <span className="stat__num line">88<span className="stat__sign">%</span></span> */}
              <span className="stat__num line">{props.stats.stat_one_percentage}</span>
              <span className="stat__txt">{props.stats.stat_one_text}</span>
            </li>
            <li className="stat">
              <span className="stat__num line">{props.stats.stat_two_percentage}</span>
              <span className="stat__txt">{props.stats.stat_two_text}</span>
            </li>
            <li className="stat">
              <span className="stat__num line">{props.stats.stat_three_percentage}</span>
              <span className="stat__txt">{props.stats.stat_three_text}</span>
            </li>
          </ul>
        </div>
      </section>
    );
  }
}

export default EnvironmentGreenPort;