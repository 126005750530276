import React from "react";

import Events from "src/components/events";
import CommunityCards from "src/components/community-cards";
import CommunityInfo from "src/components/community-info";
import NewsQuiltCommunity from "src/components/news-quilt-community";

/**
 *  Environment main page components
 */
class MainCommunity extends React.Component {
  render() {
    const props = this.props;

    return (
      <React.Fragment>
        <CommunityCards cardData={props.pageData} />
        <Events
          title="Community Calendar" 
          description={props.pageData.calendar_description}
          category="community" 
          eventsStyle="2"
          eventsType="mini-calendar"
          link={props.pageData.calendar_link}
        />
        <CommunityInfo title={props.pageData.stats_title} body={props.pageData.stats_body} stats={props.pageData} image={props.pageData.stats_image} />
        <NewsQuiltCommunity quiltStyle="pattern-01" />
      </React.Fragment>
    );
  }
}

export default MainCommunity;
